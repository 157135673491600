import OrdersApi from '../../api/OrdersApi/OrdersApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import ShippingApi from "../../api/OrdersApi/ShippingApi"
import DeliveredApi from "../../api/OrdersApi/DeliveredApi"
import { useHistory } from "react-router-dom"
import moment from 'moment-timezone'

import createProductQuantity from '../utils/createProductQuantity'

import {
    ADD_SELECTED_ROW,
    CHAT_DATA_LIST_DETAILS,
    CLEAR_DECLARATION_LIST,
    CLEAR_ORDERS_CONDITIONS,
    CLEAR_ORDERS_LIST,
    CLEAR_SELECTED_ROWS,
    LABELS_DATA_LIST_DETAILS,
    ORDERS_LIST,
    REMOVE_BAR_CODE_ERROR,
    REMOVE_CALCULATOR_ERROR,
    REMOVE_CALCULATOR_ERRORS_LIST,
    REMOVE_DETAILS_VIEW_INFO,
    REMOVE_MANUAL_ORDER_VALIDATION_ERRORS,
    REMOVE_ORDER_DECLARATION_ERROR,
    REMOVE_ORDER_DETAILS_EDIT_VALIDATION_ERRORS,
    REMOVE_ORDER_DETAILS_ERROR,
    REMOVE_SELECTED_ROW,
    SET_BAR_CODE_ERRORS_LIST,
    SET_BAR_CODE_PO_NUMBER,
    SET_BAR_CODES_LIST,
    SET_CALCULATOR_ERROR,
    SET_CALCULATOR_ERROR_LIST,
    SET_CALCULATOR_LABEL,
    SET_CALCULATOR_PRICES,
    SET_CHECK_PRICE_IN_PROGRESS,
    SET_CONFIRMATIONS,
    SET_COUNTRIES,
    SET_CREATED_DECLARATION_LIST,
    SET_CURRENT_PAGE,
    SET_CURRENT_SHOP,
    SET_SELECTED_SHOP,
    SET_CUSTOM_DECLARATION_ACTIVE,
    SET_CUSTOM_DECLARATION_ERROR,
    SET_CUSTOM_DECLARATION_IN_PROGRESS,
    SET_CUSTOM_FIELD,
    SET_DELIVERY_PACKAGES,
    SET_DETAILS_INFO_SELECTED,
    SET_DETAILS_VIEW_FULL_SCREEN,
    SET_DETAILS_VIEW_INFO,
    SET_DETAILS_PRODUCT_QUANTITY,
    REMOVE_DETAILS_PRODUCT_QUANTITY,
    SET_FLOATING_VALUE,
    SET_HS_CODES,
    SET_LABELS_LIST,
    SET_MANUAL_ORDER_ERROR,
    SET_MANUAL_ORDER_STATUS,
    SET_MANUAL_ORDER_VALIDATION_ERRORS,
    SET_ORDER_DECLARATION_ERROR_LIST,
    SET_ORDER_DETAILS_ACTIVE,
    SET_ORDER_DETAILS_EDIT_SAVE_BUTTON,
    SET_ORDER_DETAILS_ERROR,
    SET_ORDER_DETAILS_ERROR_LIST,
    SET_ORDERS_CONDITIONS,
    SET_ORDERS_SORT_CONDITIONS,
    SET_SERVICES,
    SET_SHOPS_LIST,
    SET_STATUS_FLOATING_FILTER,
    UPDATE_ORDERS_CONDITIONS,
    SET_DOWNLOAD_REPORT_STATUS,
    SET_DOWNLOAD_REPORT_LINK,
    SET_CURRENT_SHOP_ORDERS_COUNT,
    SET_FILTER_ORDERS_CURRENT_COUNT,
    SET_AUTH_ERROR,
    SET_COMMERCIAL_INVOICES,
    ORDER_CONDITION_CHANGED_STATUS,
    CUSTOM_DECLARATION,
    SET_ORDERS_LAST_TIMESTAMP,
    SET_AUTO_CREATE_DECLARATION_MANUAL_STATUS,
    SET_CREAT_LABEL_IN_PROGRESS,
    SET_COMPANY_SHOPS,
    SET_SCALES_DATA,
    SET_LABELS_LIST_FOR_TAX_ID_RULES,
    SET_SCAN_BAR_CODE_RESPONSE_RECEIVED,
    SET_RANGE_REPORT_STATUS,
    SET_ERROR_FROM_SCALES_DISPLAYED_ONCE,
    SET_RATES_ERROR_DISPLAYED_ONCE,
    SET_ORDER_COUNT,
    SET_ORDER_ITEMS_PROBLEMS,
    SET_RETURN_LABELS,
    SET_ORDER_VALIDATION_ERRORS,
    SET_ORDER_ITEM_TAG_LIST
} from "../../constants/constants"
import {getUploadingFilesList} from "../settings/uploadingFiles"
import UpcApi from "../../api/UpcApi"
// import {setScalesData} from "../upc/upcReducer"

const initialState = {
    BaseUrl: process.env.REACT_APP_API,
    OrdersList: [],
    orderItemTagList: [],
    OrdersConditions: {},
    OrdersSortConditions: { sortingColumn: null, isInverted: null },
    currentPage: 1,
    PageSize: Number(process.env.REACT_APP_ORDERS_PORTION_LIST),
    OrdersListMessage: null,
    OrdersFloatingInputValues: {},
    DetailsView: {},
    detailsProductsQuantity: [],
    DetailsViewSelected: false,
    SelectedRows: [],
    OrdersStatusFloatingFilter: '',
    Countries: [],
    Services: [],
    Confirmations: [],
    DeliveryPackages: null,
    OrderDeclarationErrorList: null,
    isCustomDeclarationActive: false,
    isOrderDetailsActive: false,
    isActive_DetailsEdit_SaveButton: true,
    OrderCalculator_ErrorList: null,
    CalculatorPrices: null,
    isCheckPricesInProgress: false,
    isCustomDeclarationInProgress: false,
    CreatedDeclarationList: [],
    BarCodePoNumber: null,
    BarCodesList: [],
    BarCodeErrorsList: [],
    Manual_ValidationErrors: [],
    Manual_Order_Status: {
        Status: null,
        InProgress: false
    },
    HsCodes: [],
    LabelsList: [/*{tracker_number: '555-555-555-555'}*/],
    ShopsList: [],
    CurrentShop: /*JSON.parse(window.localStorage.getItem('OrdersShop'))*/{ shop_id: "all" }, // { name: string, shop_id: number }
    selectedShop: null,
    LabelsDataList: [],
    ChatDataList: [],
    CustomFieldData: [],
    ButtonStatuses: {
        DownloadReportInProgress: false,
        RangeReportInProgress: false
    },
    DownloadReportLink: null,
    CurrentOrdersCount: null,
    CurrentOrdersCountFiltered: null,
    CommercialInvoices: null,
    AutoCreateDeclarationManualStatus: false,
    CompanyShopsList: [],
    ScalesData: {},
    LabelsListTaxIdRules: [],
    ScanBarCode_ResponseReceived: true,
    RatesErrorDisplayedOnce: false,
    OrderCount: 0,
    ItemsProblems: [],
    ReturnLabelsList: [],
    OrderValidationErrors: {}
}

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
      case ORDERS_LIST:
          return {
              ...state,
              OrdersList: [...state.OrdersList, ...action.order_list].filter((v, i, a) => a.findIndex(t => (t.order_id === v.order_id)) === i)
          }
      case CLEAR_ORDERS_LIST:
          return {
              ...state,
              OrdersList: []
          }
      case SET_FLOATING_VALUE:
          return {
              ...state,
              OrdersFloatingInputValues: {...state.OrdersFloatingInputValues, [action.key]: action.value}
          }
      case SET_DETAILS_VIEW_INFO:
          return {
              ...state,
              DetailsView : {
                  ...state.DetailsView,
                  selected: action.selected,
                  data: action.data
              }
          }
      case SET_DETAILS_PRODUCT_QUANTITY:
          return {
              ...state,
              detailsProductsQuantity : action.data
          }
        case REMOVE_DETAILS_PRODUCT_QUANTITY:
            return {
                ...state,
                detailsProductsQuantity : []
            }
      case SET_DETAILS_VIEW_FULL_SCREEN:
          return {
              ...state,
              DetailsView : {
                  ...state.DetailsView, isFullScreen: action.isFullScreen
              }
          }
      case REMOVE_DETAILS_VIEW_INFO:
          return {
              ...state,
              DetailsView : null
          }
      case SET_DETAILS_INFO_SELECTED:
          return {
              ...state,
              DetailsViewSelected : action.selected
          }
      case ADD_SELECTED_ROW:
          return {
              ...state,
              SelectedRows : [...state.SelectedRows, {id: action.id, data: action.data}]
          }
      case REMOVE_SELECTED_ROW:
          return {
              ...state,
              SelectedRows : state.SelectedRows.filter(row => row.id !== action.id)
          }
      case CLEAR_SELECTED_ROWS:
          return {
              ...state,
              SelectedRows : [],
              DetailsViewSelected: false
          }
      case SET_ORDERS_CONDITIONS:
          return {
              ...state,
              OrdersConditions: {...state.OrdersConditions, [action.condition_name]: {...state.OrdersConditions[action.condition_name], "filter": action.condition_value}},
              currentPage: 1,
              OrdersList: []
          }
      case CLEAR_ORDERS_CONDITIONS:
          return {
              ...state,
              OrdersConditions: {},
              currentPage: 1
          }
      case SET_ORDERS_SORT_CONDITIONS:
          return {
              ...state,
              OrdersSortConditions: {sortingColumn: action.sortingColumn, isInverted: action.isInverted},
              currentPage: 1
          }
      case UPDATE_ORDERS_CONDITIONS:
          return {
              ...state,
              OrdersConditions: {...state.OrdersConditions, [action.sortingColumn]: {...state.OrdersConditions[action.sortingColumn], "order_by": action.sort_by}},
              currentPage: 1,
              OrdersList: []
          }
      case SET_STATUS_FLOATING_FILTER:
          return {
              ...state,
              OrdersStatusFloatingFilter: action.status
          }
      case SET_COUNTRIES:
          return {
              ...state,
              Countries: action.countries
          }
      case SET_ORDER_DECLARATION_ERROR_LIST:
          return {
              ...state,
              OrderDeclarationErrorList: action.list
          }
      case SET_CUSTOM_DECLARATION_ERROR:
          return {
              ...state,
              OrderDeclarationErrorList: {...state.OrderDeclarationErrorList, [action.field]: [action.error_name]}
          }
      case REMOVE_ORDER_DECLARATION_ERROR:
          return {
              ...state,
              OrderDeclarationErrorList: {...state.OrderDeclarationErrorList, [action.key]: null}
          }
      case SET_CUSTOM_DECLARATION_ACTIVE:
          return {
              ...state,
              isCustomDeclarationActive: action.active
          }
      case SET_ORDER_DETAILS_ERROR_LIST:
          return {
              ...state,
              OrderDetails_ErrorList: action.list
          }
      case SET_ORDER_DETAILS_ERROR:
          return {
              ...state,
              OrderDetails_ErrorList: {...state.OrderDeclarationErrorList, [action.field]: [action.error_name]}
          }
      case REMOVE_ORDER_DETAILS_ERROR:
          return {
              ...state,
              OrderDetails_ErrorList: {...state.OrderDetails_ErrorList, [action.key]: null}
          }
      case SET_ORDER_DETAILS_ACTIVE:
          return {
              ...state,
              isOrderDetailsActive: action.active
          }
      case SET_ORDER_DETAILS_EDIT_SAVE_BUTTON:
          return {
              ...state,
              isActive_DetailsEdit_SaveButton: action.active
          }
      case REMOVE_ORDER_DETAILS_EDIT_VALIDATION_ERRORS:
          return {
              ...state,
              OrderDetails_ErrorList: null
          }
      case SET_CALCULATOR_ERROR_LIST:
          return {
              ...state,
              OrderCalculator_ErrorList: action.list
          }
      case SET_CALCULATOR_ERROR:
          return {
              ...state,
              OrderCalculator_ErrorList: {...state.OrderCalculator_ErrorList, [action.field]: [action.error_name]}
          }
      case REMOVE_CALCULATOR_ERROR:
          return {
              ...state,
              OrderCalculator_ErrorList: {...state.OrderCalculator_ErrorList, [action.key]: null}
          }
      case REMOVE_CALCULATOR_ERRORS_LIST:
          return {
              ...state,
              OrderCalculator_ErrorList: null
          }
      case SET_SERVICES:
          return {
              ...state,
              Services: action.services
          }
      case SET_CONFIRMATIONS:
          return {
              ...state,
              Confirmations: action.list
          }
      case SET_DELIVERY_PACKAGES:
          return {
              ...state,
              DeliveryPackages: action.packages
          }
      case SET_CALCULATOR_PRICES:
          return {
              ...state,
              CalculatorPrices: action.prices
          }
      case SET_CALCULATOR_LABEL:
          return {
              ...state,
              CalculatorLabelUrl: baseUrl() + action.url
          }
      case SET_CHECK_PRICE_IN_PROGRESS:
          return {
              ...state,
              isCheckPricesInProgress: action.boolean
          }
      case SET_CUSTOM_DECLARATION_IN_PROGRESS:
          return {
              ...state,
              isCustomDeclarationInProgress: action.boolean
          }
      case SET_CREATED_DECLARATION_LIST:
          return {
              ...state,
              CreatedDeclarationList: [...state.CreatedDeclarationList, action.asin]
          }
      case CLEAR_DECLARATION_LIST:
          return {
              ...state,
              CreatedDeclarationList: state.CreatedDeclarationList.filter(function(item) {
                  return item !== action.asin
              })
          }
      case SET_CURRENT_PAGE:
          return {
              ...state,
              currentPage: action.currentPage > 0 ? action.currentPage : 1
          }
      case SET_BAR_CODE_PO_NUMBER:
          return {
              ...state,
              BarCodePoNumber: action.po_number
          }
      case SET_BAR_CODES_LIST :
          return {
              ...state,
              BarCodesList: action.list
          }
      case SET_BAR_CODE_ERRORS_LIST:
          return {
              ...state,
              BarCodeErrorsList: action.list
          }
      case REMOVE_BAR_CODE_ERROR:
          return {
              ...state,
              BarCodeErrorsList: {...state.BarCodeErrorsList, [action.key]: null}

          }
      case SET_MANUAL_ORDER_VALIDATION_ERRORS:
          return {
              ...state,
              Manual_ValidationErrors: action.list
          }
      case SET_MANUAL_ORDER_ERROR:
          return {
              ...state,
              Manual_ValidationErrors: {...state.Manual_ValidationErrors, [action.field]: [action.error_name]}
          }
      case REMOVE_MANUAL_ORDER_VALIDATION_ERRORS:
          return {
              ...state,
              Manual_ValidationErrors: {...state.Manual_ValidationErrors, [action.key]: null}
          }
      case SET_MANUAL_ORDER_STATUS :
          return {
              ...state,
              Manual_Order_Status: {Status: action.status, InProgress: action.inProgress}

          }
      case SET_HS_CODES :
          return {
              ...state,
              HsCodes: action.list

          }
      case SET_SHOPS_LIST :
          return {
              ...state,
              ShopsList: action.list.sort((a, b) => { return a.shop_name.localeCompare(b.shop_name) })
          }
      case SET_CURRENT_SHOP :
          return {
              ...state,
              CurrentShop: action.shop,
              CurrentShops: action.shops_list

          }
      case SET_SELECTED_SHOP :
          return {
              ...state,
              selectedShop: action.payload
          }
      case SET_LABELS_LIST :
          return {
              ...state,
              LabelsList: action.list
          }
      case LABELS_DATA_LIST_DETAILS :
          let foo = action.list.sort(function(a, b) {
              return new Date(a.created_at) - new Date(b.created_at)
          })
          return {
              ...state,
              LabelsDataList: action.list.sort(function(a, b) {
                  return new Date(a.created_at) - new Date(b.created_at)
              })
          }
      case CHAT_DATA_LIST_DETAILS :
          let СhatData = action.list.sort(function(a, b) {
              return new Date(b.created_at) - new Date(a.created_at)
          })
          СhatData.map(d => {
              let date = d.created_at.replace(/\.+.*/g, '')
              date = date.replace(' ', 'T')
              let convertedDate = moment(date)
              const fullDate = moment(convertedDate).format("YYYY-MM-DD hh:mm:ss A")
              d.created_at = fullDate
          })
       return {
           ...state,
           ChatDataList: СhatData
       }
      case SET_CUSTOM_FIELD :
          return {
              ...state,
              CustomFieldData: action.list
          }
      case SET_DOWNLOAD_REPORT_STATUS :
          return {
              ...state,
              ButtonStatuses: {...state.ButtonStatuses, DownloadReportInProgress: action.status}
          }
      case SET_RANGE_REPORT_STATUS :
          return {
              ...state,
              ButtonStatuses: {...state.ButtonStatuses, RangeReportInProgress: action.payload}
          }
      case SET_CURRENT_SHOP_ORDERS_COUNT :
          return {
              ...state,
              CurrentOrdersCount: action.orders_count
/*
              CurrentShop: {...state.CurrentShop, orders_count: action.orders_count}
*/
          }
      case SET_FILTER_ORDERS_CURRENT_COUNT :
          return {
              ...state,
              CurrentOrdersCountFiltered: action.count
          }
      case SET_AUTH_ERROR :
          return {
              ...state,
              AuthErrorMessage: action.error
          }
      case SET_COMMERCIAL_INVOICES :
          return {
              ...state,
              CommercialInvoices: action.invoices
          }
      case ORDER_CONDITION_CHANGED_STATUS :
          return {
              ...state,
              OrdersCondionsChanged: action.status
          }
      case CUSTOM_DECLARATION :
          return {
              ...state,
              CustomDeclaration: action.declaration
          }
      case SET_ORDERS_LAST_TIMESTAMP :
          return {
              ...state,
              OrdersLastTimestamp: action.timestamp
          }
      case SET_AUTO_CREATE_DECLARATION_MANUAL_STATUS :
          return {
              ...state,
              AutoCreateDeclarationManualStatus: action.payload
          }
      case SET_CREAT_LABEL_IN_PROGRESS :
          return {
              ...state,
              CreateLabelInProgress: action.boolean
          }
      case SET_COMPANY_SHOPS :
          let sortedList = action.payload.sort((a, b) => { return a.company_name ? a.company_name.localeCompare(b.company_name || '') : ''.localeCompare(b.company_name || '') })
          return {
              ...state,
              CompanyShopsList: sortedList
          }
      case SET_SCALES_DATA :
          return {
              ...state,
              ScalesData: action.scales_data
          }
      case SET_LABELS_LIST_FOR_TAX_ID_RULES :
          return {
              ...state,
              LabelsListTaxIdRules: action.payload
          }
      case SET_SCAN_BAR_CODE_RESPONSE_RECEIVED :
          return {
          ...state,
          ScanBarCode_ResponseReceived: action.payload
      }
      case SET_RATES_ERROR_DISPLAYED_ONCE :
          return {
              ...state,
              RatesErrorDisplayedOnce: action.payload
          }
      case SET_ORDER_COUNT :
          return {
              ...state,
              OrderCount: action.payload
          }
      case SET_ORDER_ITEMS_PROBLEMS :
          return {
              ...state,
              ItemsProblems: action.payload
          }
      case SET_RETURN_LABELS :
          return {
              ...state,
              ReturnLabelsList: action.payload
          }
      case SET_ORDER_VALIDATION_ERRORS :
          return {
              ...state,
              OrderValidationErrors: action.payload
          }
      case SET_ORDER_ITEM_TAG_LIST:
          return {
              ...state,
              orderItemTagList: action.payload
          }

    default:
      return state
  }
}


export const setOrdersList = (order_list) => ({type: ORDERS_LIST, order_list})
export const setOrderItemTagList = (payload) => ({type: SET_ORDER_ITEM_TAG_LIST, payload})
export const clearOrdersList = () => ({type: CLEAR_ORDERS_LIST})
export const setOrdersFloatingInputValues = (key, value) => ({type: SET_FLOATING_VALUE, key, value})
export const setDetailsViewInfo = (data, selected) => ({type: SET_DETAILS_VIEW_INFO, selected, data})
export const setDetailsProductQuantity = (data) => ({type: SET_DETAILS_PRODUCT_QUANTITY, data})
export const removeDetailsProductQuantity = () => ({type: REMOVE_DETAILS_PRODUCT_QUANTITY})
export const setDetailsViewFullScreen = (isFullScreen) => ({type: SET_DETAILS_VIEW_FULL_SCREEN, isFullScreen})
export const removeDetailsViewInfo = () => ({type: REMOVE_DETAILS_VIEW_INFO})
export const setDetailsViewSelected = (selected) => ({type: SET_DETAILS_INFO_SELECTED, selected})
export const addSelectedRow = (id, data) => ({type: ADD_SELECTED_ROW, id, data})
export const removeSelectedRow = (id) => ({type: REMOVE_SELECTED_ROW, id})
export const clearSelectedRow = () => ({type: CLEAR_SELECTED_ROWS})
export const setOrdersConditions = (condition_name, condition_value) => ({type: SET_ORDERS_CONDITIONS, condition_name, condition_value})
export const clearOrdersConditions = () => ({type: CLEAR_ORDERS_CONDITIONS})
export const setOrdersSortConditions = (sortingColumn, isInverted) => ({
    // set actual orders sort conditions
    type: SET_ORDERS_SORT_CONDITIONS, sortingColumn, isInverted
    })
export const updateOrdersConditions = (sortingColumn, isInverted) => ({
    // after each sorting click we set our sort conditions into OrdersConditions object which should be send in API
    type: UPDATE_ORDERS_CONDITIONS, sortingColumn, sort_by: isInverted ? "DESC" : (isInverted === false ? "ASC" : null)
})
export const setStatusFloatingFilter = (status) => ({type: SET_STATUS_FLOATING_FILTER, status})
export const setCountries = (countries) => ({type: SET_COUNTRIES, countries})

export const setCreateDeclarationValidationList = (list) => ({type: SET_ORDER_DECLARATION_ERROR_LIST, list})
export const removeCreateDeclarationValidationItem = (key) => ({type: REMOVE_ORDER_DECLARATION_ERROR, key})
export const setCostomDeclarationActive = (active) => ({type: SET_CUSTOM_DECLARATION_ACTIVE, active})
export const pushCustomDeclarationValidationError = (field, error_name) => ({type: SET_CUSTOM_DECLARATION_ERROR, field, error_name})

export const setDetailsEdit_validation_errors = (list) => ({type: SET_ORDER_DETAILS_ERROR_LIST, list})
export const removeDetailsEdit_validation_error = (key) => ({type: REMOVE_ORDER_DETAILS_ERROR, key})
export const setOrderDetailsEditActive = (active) => ({type: SET_ORDER_DETAILS_ACTIVE, active})
export const setOrderDetailsEditSaveButtonActive = (active) => ({type: SET_ORDER_DETAILS_EDIT_SAVE_BUTTON, active})
export const removeDetailsEdit_all_validation_errors = () => ({type: REMOVE_ORDER_DETAILS_EDIT_VALIDATION_ERRORS})
export const pushDetailsEditValidationError = (field, error_name) => ({type: SET_ORDER_DETAILS_ERROR, field, error_name})


export const setCalculatorErrorList = (list) => ({type: SET_CALCULATOR_ERROR_LIST, list})
export const pushCalculatorError = (field, error_name) => ({type: SET_CALCULATOR_ERROR, field, error_name})
export const removeCalculatorError = (key) => ({type: REMOVE_CALCULATOR_ERROR, key})
export const clearCalculatorErrors = () => ({type: REMOVE_CALCULATOR_ERRORS_LIST})
export const setServices = (services) => ({type: SET_SERVICES, services})
export const SetConfirmations = (list) => ({type: SET_CONFIRMATIONS, list})
export const setDeliveryPackages = (packages) => ({type: SET_DELIVERY_PACKAGES, packages})
export const setCalculatorPrices = (prices) => ({type: SET_CALCULATOR_PRICES, prices})
export const setLabelUrl = (url) => ({type: SET_CALCULATOR_LABEL, url})
export const setCheckPriceInProgress = (boolean) => ({type: SET_CHECK_PRICE_IN_PROGRESS, boolean})
export const setCustomDeclarationInProgress = (boolean) => ({type: SET_CUSTOM_DECLARATION_IN_PROGRESS, boolean})
export const setCreatedDeclarationList = (asin) => ({type: SET_CREATED_DECLARATION_LIST, asin})
export const clearDeclarationList = (asin) => ({type: CLEAR_DECLARATION_LIST, asin})
export const setCurrentPage = (currentPage) => ({type: SET_CURRENT_PAGE, currentPage})

export const openBarCodeByOrderId = (po_number) => ({type: SET_BAR_CODE_PO_NUMBER, po_number})
export const setBarCodesList = (list) => ({type: SET_BAR_CODES_LIST, list})
export const setBarCodeErrorList = (list) => ({type: SET_BAR_CODE_ERRORS_LIST, list })
export const removeBarCodeError = (key) => ({type: REMOVE_BAR_CODE_ERROR, key })

export const setManualOrderValidationErrors = (list) => ({type: SET_MANUAL_ORDER_VALIDATION_ERRORS, list})
export const pushManualOrderValidationError = (field, error_name) => ({type: SET_MANUAL_ORDER_ERROR, field, error_name})
export const removeManualOrderValidationErrors = (key) => ({type: REMOVE_MANUAL_ORDER_VALIDATION_ERRORS, key})
export const setMaualOrderStatus = (status, inProgress) => ({type: SET_MANUAL_ORDER_STATUS, status, inProgress})
export const setHsCodes = (list) => ({type: SET_HS_CODES, list})

export const setShopsList = (list) => ({type: SET_SHOPS_LIST, list})
export const setCurrentShop = (shop, shops_list) => ({type: SET_CURRENT_SHOP, shop, shops_list})
export const setSelectedShop = (payload) => ({type: SET_SELECTED_SHOP, payload})
export const setLabelsList = (list) => ({type: SET_LABELS_LIST, list})
export const setCreateLabelInProgress = (boolean) => ({type: SET_CREAT_LABEL_IN_PROGRESS, boolean})

export const setLabelsDataList = (list) => ({type: LABELS_DATA_LIST_DETAILS, list})
export const setChatDataList = (list) => ({type: CHAT_DATA_LIST_DETAILS, list})
export const setCustomField = (list) => ({type: SET_CUSTOM_FIELD, list})

export const setOrderItemsProblems = (payload) => ({type: SET_ORDER_ITEMS_PROBLEMS, payload})

export const setDownloadReportInProgress = (status) => ({type: SET_DOWNLOAD_REPORT_STATUS, status})
export const setCurrentShopOrdersCount = (orders_count) => ({type: SET_CURRENT_SHOP_ORDERS_COUNT, orders_count})
export const setRangeReportInProgress = (payload) => ({type: SET_RANGE_REPORT_STATUS, payload})

export const setCurrentOrdersCountFiltered = (count) => ({type: SET_FILTER_ORDERS_CURRENT_COUNT, count})

export const setAuthError = (error) => ({type: SET_AUTH_ERROR, error})

export const setCommercialInvoices = (invoices) => ({type: SET_COMMERCIAL_INVOICES, invoices})

export const setOrderConditionChangedStatus = (status) => ({type: ORDER_CONDITION_CHANGED_STATUS, status})

export const setCustomDeclaration = (declaration) => ({type: CUSTOM_DECLARATION, declaration})

export const setOrdersLastTimestamp = (timestamp) => ({type: SET_ORDERS_LAST_TIMESTAMP, timestamp})

export const setAutoCreateDeclarationManualStatus = (payload) => ({type: SET_AUTO_CREATE_DECLARATION_MANUAL_STATUS, payload})

export const setCompanyShopsList = (payload) => ({type: SET_COMPANY_SHOPS, payload})

export const setLabelsListForTaxIdRules = (payload) => ({type: SET_LABELS_LIST_FOR_TAX_ID_RULES, payload})

export const setScanBarCode_ResponseReceived = (payload) => ({type: SET_SCAN_BAR_CODE_RESPONSE_RECEIVED, payload})

export const setOrdersCount = (payload) => ({type: SET_ORDER_COUNT, payload})

export const setReturnLabelsList = (payload) => ({type: SET_RETURN_LABELS, payload})

export const setOrdersValidationErrors = (payload) => ({type: SET_ORDER_VALIDATION_ERRORS, payload})

export const updateAndSetSortOrdersConditions = (sortingColumn, isInverted) => (dispatch) => {
    dispatch(setOrdersSortConditions(sortingColumn, isInverted))
    dispatch(updateOrdersConditions(sortingColumn, isInverted))
}

export const setRatesErrorDisplayedOnce = (payload) => ({type: SET_RATES_ERROR_DISPLAYED_ONCE, payload})

export const handleCurrentPageChange = (currentPage, getOrdersMethod) => (dispatch, getState) => {
    if (currentPage > getState().orders.currentPage) {
        dispatch(setCurrentPage(currentPage))
        dispatch(getOrdersMethod())
        return true
    }
    return false
}
export const CheckAndNotifyErrors = (error) => {
    if (error?.response?.data?.validation_errors.order_item_quantity) {
        Notify(error?.response?.data?.validation_errors.order_item_quantity, 'Error', 'error')
        return
    }
    if (error?.response?.data?.message) {
        Notify(error?.response?.data?.message, `${error?.response?.data?.status || "Error"}`, 'error')
    }
    if (error?.response?.data?.messages) {
        error?.response?.data?.messages.map(m => {
            Notify(m, `${error?.response?.data?.status || "Error"}`, 'error')
        })
    }
    /*if (error?.response?.data?.validation_errors) {
        Object.entries(error?.response?.data?.validation_errors).map(([key, value]) => {
            value.map(e => Notify(e, `${error?.response?.data?.status || "Error"}`, 'error'))
        })
    }*/
}

export const LogOutOnAuthError = (error) => (dispatch, getState) => {
    error?.response?.data?.messages?.map(m => {
        if (m === "Authorization error") {
            window.localStorage.clear()
            window.localStorage.setItem('isAuthError', true)
            window.location.pathname = '/login'
            Notify(m, 'Error', 'error')
        }
    })
}

export const CheckAndNotify = (response, show_success)  => {
    if (response.data.status && response.data.status === 'INFO') {
        Notify(response.data.message, "Info", 'info')
    } else if (response.data.status && response.data.status === 'WARNING') {
        Notify(response.data.message, "Warning", 'warning')
    }
    if (show_success && response.data.status === "OK") {
        Notify(response.data.message, 'Success', 'success')
    }
}

export const getOrdersList = (responseType, sortedColumns) => (dispatch, getState) => {
    if (responseType === 'file') Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    let CurrentTimestamp = new Date().getTime()
    dispatch(setOrdersLastTimestamp(CurrentTimestamp))
    dispatch(setOrdersCount(0))
    dispatch(getOrderTabsCountByConditions('awaiting', CurrentTimestamp))
    let conditions = Object.assign({}, getState().orders.OrdersConditions)
    if (sortedColumns) {
        sortedColumns.forEach(c => {
            let sort_by
            if (c.sort === "asc") {
                sort_by = 'ASC'
            } else if (c.sort === "desc") {
                sort_by = 'DESC'
            }
            if (sort_by) {
                if (conditions[c.colId]) {
                    conditions[c.colId].order_by = sort_by
                } else {
                    conditions[c.colId] = {"order_by": sort_by}
                }
            }
        })
    }
    OrdersApi.postList(getState().auth.AuthToken,
        getState().orders.CurrentShops || (getState().orders.CurrentShop?.shop_id === "all" ? "all" : [getState().orders.CurrentShop?.shop_id]),
        getState().orders.PageSize,
        getState().orders.OrdersList.length,
        responseType === 'file' ? conditions : getState().orders.OrdersConditions, responseType).then(response => {
        CheckAndNotify(response)
        if (CurrentTimestamp === getState().orders.OrdersLastTimestamp) {
                if (responseType) {
                    Notify('Report has been successfully downloaded', 'Success', 'success')
                    dispatch(setDownloadReportInProgress(false))
                    window.open(`${getState().orders.BaseUrl}/${response.data.data.data.report_file_url}`, '_blank', 'noopener,noreferrer')
                } else {
                    dispatch(setCurrentOrdersCountFiltered(response.data.data?.countData))
                    dispatch(setOrdersList(response.data.data.data))
                    if (getState().orders.OrdersSortConditions.isInverted === true) {
                        dispatch(setOrdersSortConditions(getState().orders.OrdersSortConditions.sortingColumn, null))
                    }
                }
            }
    }).catch(error => {
        dispatch(setDownloadReportInProgress(false))
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getPrintLateList = (responseType, sortedColumns) => (dispatch, getState) => {
    if (responseType === 'file') Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    let CurrentTimestamp = new Date().getTime()
    dispatch(setOrdersLastTimestamp(CurrentTimestamp))
    dispatch(setOrdersCount(0))
    dispatch(getOrderTabsCountByConditions('print-late', CurrentTimestamp))
    let conditions = Object.assign({}, getState().orders.OrdersConditions)
    if (sortedColumns) {
        sortedColumns.forEach(c => {
            let sort_by
            if (c.sort === "asc") {
                sort_by = 'ASC'
            } else if (c.sort === "desc") {
                sort_by = 'DESC'
            }
            if (sort_by) {
                if (conditions[c.colId]) {
                    conditions[c.colId].order_by = sort_by
                } else {
                    conditions[c.colId] = {"order_by": sort_by}
                }
            }
        })
    }
    OrdersApi.postPrintLateList(getState().auth.AuthToken,
        getState().orders.CurrentShops || (getState().orders.CurrentShop?.shop_id === "all" ? "all" : [getState().orders.CurrentShop?.shop_id]),
        getState().orders.PageSize,
        getState().orders.OrdersList.length,
        responseType === 'file' ? conditions : getState().orders.OrdersConditions, responseType).then(response => {
        CheckAndNotify(response)

        if (CurrentTimestamp === getState().orders.OrdersLastTimestamp) {
            if (responseType) {
                Notify('Report has been successfully downloaded', 'Success', 'success')
                dispatch(setDownloadReportInProgress(false))
                window.open(`${getState().orders.BaseUrl}/${response.data.data.data.report_file_url}`, '_blank', 'noopener,noreferrer')
            } else {
                dispatch(setCurrentOrdersCountFiltered(response.data.data?.countData))
                dispatch(setOrdersList(response.data.data.data))
                if (getState().orders.OrdersSortConditions.isInverted === true) {
                    dispatch(setOrdersSortConditions(getState().orders.OrdersSortConditions.sortingColumn, null))
                }
            }
        }
    }).catch(error => {
        dispatch(setDownloadReportInProgress(false))
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
      
    })
}

export const getReturnRequestList = (responseType, sortedColumns) => (dispatch, getState) => {
    if (responseType === 'file') Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    let CurrentTimestamp = new Date().getTime()
    dispatch(setOrdersLastTimestamp(CurrentTimestamp))
    dispatch(setOrdersCount(0))
    dispatch(getOrderTabsCountByConditions('return-request', CurrentTimestamp))
    let conditions = Object.assign({}, getState().orders.OrdersConditions)
    if (sortedColumns) {
        sortedColumns.forEach(c => {
            let sort_by
            if (c.sort === "asc") {
                sort_by = 'ASC'
            } else if (c.sort === "desc") {
                sort_by = 'DESC'
            }
            if (sort_by) {
                if (conditions[c.colId]) {
                    conditions[c.colId].order_by = sort_by
                } else {
                    conditions[c.colId] = {"order_by": sort_by}
                }
            }
        })
    }
    OrdersApi.postReturnRequestList(getState().auth.AuthToken,
        getState().orders.CurrentShops || (getState().orders.CurrentShop?.shop_id === "all" ? "all" : [getState().orders.CurrentShop?.shop_id]),
        getState().orders.PageSize,
        getState().orders.OrdersList.length,
        responseType === 'file' ? conditions : getState().orders.OrdersConditions, responseType).then(response => {
        CheckAndNotify(response)

        if (CurrentTimestamp === getState().orders.OrdersLastTimestamp) {
            if (responseType) {
                Notify('Report has been successfully downloaded', 'Success', 'success')
                dispatch(setDownloadReportInProgress(false))
                window.open(`${getState().orders.BaseUrl}/${response.data.data.data.report_file_url}`, '_blank', 'noopener,noreferrer')
            } else {
                dispatch(setCurrentOrdersCountFiltered(response.data.data?.countData))
                dispatch(setOrdersList(response.data.data.data))
                if (getState().orders.OrdersSortConditions.isInverted === true) {
                    dispatch(setOrdersSortConditions(getState().orders.OrdersSortConditions.sortingColumn, null))
                }
            }
        }
    }).catch(error => {
        dispatch(setDownloadReportInProgress(false))
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}
export const getReturnList = (responseType, sortedColumns) => (dispatch, getState) => {
    if (responseType === 'file') Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    let CurrentTimestamp = new Date().getTime()
    dispatch(setOrdersLastTimestamp(CurrentTimestamp))
    dispatch(setOrdersCount(0))
    dispatch(getOrderTabsCountByConditions('return-request', CurrentTimestamp))
    let conditions = Object.assign({}, getState().orders.OrdersConditions)
    if (sortedColumns) {
        sortedColumns.forEach(c => {
            let sort_by
            if (c.sort === "asc") {
                sort_by = 'ASC'
            } else if (c.sort === "desc") {
                sort_by = 'DESC'
            }
            if (sort_by) {
                if (conditions[c.colId]) {
                    conditions[c.colId].order_by = sort_by
                } else {
                    conditions[c.colId] = {"order_by": sort_by}
                }
            }
        })
    }
    OrdersApi.postReturnList(getState().auth.AuthToken,
        getState().orders.CurrentShops || (getState().orders.CurrentShop?.shop_id === "all" ? "all" : [getState().orders.CurrentShop?.shop_id]),
        getState().orders.PageSize,
        getState().orders.OrdersList.length,
        responseType === 'file' ? conditions : getState().orders.OrdersConditions, responseType).then(response => {
        CheckAndNotify(response)

        if (CurrentTimestamp === getState().orders.OrdersLastTimestamp) {
            if (responseType) {
                Notify('Report has been successfully downloaded', 'Success', 'success')
                dispatch(setDownloadReportInProgress(false))
                window.open(`${getState().orders.BaseUrl}/${response.data.data.data.report_file_url}`, '_blank', 'noopener,noreferrer')
            } else {
                dispatch(setCurrentOrdersCountFiltered(response.data.data?.countData))
                dispatch(setOrdersList(response.data.data.data))
                if (getState().orders.OrdersSortConditions.isInverted === true) {
                    dispatch(setOrdersSortConditions(getState().orders.OrdersSortConditions.sortingColumn, null))
                }
            }
        }
    }).catch(error => {
        dispatch(setDownloadReportInProgress(false))
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getShippingList = (responseType, sortedColumns) => (dispatch, getState) => {
    if (responseType === 'file') Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    let CurrentTimestamp = new Date().getTime()
    dispatch(setOrdersLastTimestamp(CurrentTimestamp))
    dispatch(setOrdersCount(0))
    dispatch(getOrderTabsCountByConditions('shipping', CurrentTimestamp))
    let conditions = Object.assign({}, getState().orders.OrdersConditions)
    if (sortedColumns) {
        sortedColumns.forEach(c => {
            let sort_by
            if (c.sort === "asc") {
                sort_by = 'ASC'
            } else if (c.sort === "desc") {
                sort_by = 'DESC'
            }
            if (sort_by) {
                if (conditions[c.colId]) {
                    conditions[c.colId].order_by = sort_by
                } else {
                    conditions[c.colId] = {"order_by": sort_by}
                }
            }
        })
    }
    ShippingApi.postList(getState().auth.AuthToken,
        getState().orders.CurrentShops || (getState().orders.CurrentShop?.shop_id === "all" ? "all" : [getState().orders.CurrentShop?.shop_id]),
        getState().orders.PageSize,
        getState().orders.OrdersList.length,
        responseType === 'file' ? conditions : getState().orders.OrdersConditions, responseType).then(response => {
                CheckAndNotify(response)
                if (CurrentTimestamp === getState().orders.OrdersLastTimestamp) {
                    if (responseType) {
                        Notify('Report has been successfully downloaded', 'Success', 'success')
                        dispatch(setDownloadReportInProgress(false))
                        window.open(`${getState().orders.BaseUrl}/${response.data.data.data.report_file_url}`, '_blank', 'noopener,noreferrer')
                    } else {
                        dispatch(setCurrentOrdersCountFiltered(response.data.data?.countData))
                        dispatch(setOrdersList(response.data.data.data))
                        if (getState().orders.OrdersSortConditions.isInverted === true) {
                            dispatch(setOrdersSortConditions(getState().orders.OrdersSortConditions.sortingColumn, null))
                        }
                    }
                }

    }).catch(error => {
        dispatch(setDownloadReportInProgress(false))
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
      
    })
}

export const getReceivedList = (responseType, sortedColumns) => (dispatch, getState) => {
    if (responseType === 'file') Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    let CurrentTimestamp = new Date().getTime()
    dispatch(setOrdersLastTimestamp(CurrentTimestamp))
    dispatch(setOrdersCount(0))
    dispatch(getOrderTabsCountByConditions('received', CurrentTimestamp))
    let conditions = Object.assign({}, getState().orders.OrdersConditions)
    if (sortedColumns) {
        sortedColumns.forEach(c => {
            let sort_by
            if (c.sort === "asc") {
                sort_by = 'ASC'
            } else if (c.sort === "desc") {
                sort_by = 'DESC'
            }
            if (sort_by) {
                if (conditions[c.colId]) {
                    conditions[c.colId].order_by = sort_by
                } else {
                    conditions[c.colId] = {"order_by": sort_by}
                }
            }
        })
    }
    OrdersApi.postReceivedList(getState().auth.AuthToken,
        getState().orders.CurrentShops || (getState().orders.CurrentShop?.shop_id === "all" ? "all" : [getState().orders.CurrentShop?.shop_id]),
        getState().orders.PageSize,
        getState().orders.OrdersList.length,
        responseType === 'file' ? conditions : getState().orders.OrdersConditions, responseType).then(response => {
        CheckAndNotify(response)

        if (CurrentTimestamp === getState().orders.OrdersLastTimestamp) {
            if (responseType) {
                Notify('Report has been successfully downloaded', 'Success', 'success')
                dispatch(setDownloadReportInProgress(false))
                window.open(`${getState().orders.BaseUrl}/${response.data.data.data.report_file_url}`, '_blank', 'noopener,noreferrer')
            } else {
                dispatch(setCurrentOrdersCountFiltered(response.data.data?.countData))
                dispatch(setOrdersList(response.data.data.data))
                if (getState().orders.OrdersSortConditions.isInverted === true) {
                    dispatch(setOrdersSortConditions(getState().orders.OrdersSortConditions.sortingColumn, null))
                }
            }
        }
    }).catch(error => {
        dispatch(setDownloadReportInProgress(false))
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
      
    })
}

export const getDeliveredList = (responseType, sortedColumns) => (dispatch, getState) => {
    if (responseType === 'file') Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    let CurrentTimestamp = new Date().getTime()
    dispatch(setOrdersLastTimestamp(CurrentTimestamp))
    dispatch(setOrdersCount(0))
    dispatch(getOrderTabsCountByConditions('delivered', CurrentTimestamp))
    let conditions = Object.assign({}, getState().orders.OrdersConditions)
    if (sortedColumns) {
        sortedColumns.forEach(c => {
            let sort_by
            if (c.sort === "asc") {
                sort_by = 'ASC'
            } else if (c.sort === "desc") {
                sort_by = 'DESC'
            }
            if (sort_by) {
                if (conditions[c.colId]) {
                    conditions[c.colId].order_by = sort_by
                } else {
                    conditions[c.colId] = {"order_by": sort_by}
                }
            }
        })
    }
    DeliveredApi.postList(getState().auth.AuthToken,
        getState().orders.CurrentShops || (getState().orders.CurrentShop?.shop_id === "all" ? "all" : [getState().orders.CurrentShop?.shop_id]),
        getState().orders.PageSize,
        getState().orders.OrdersList.length,
        responseType === 'file' ? conditions : getState().orders.OrdersConditions, responseType).then(response => {
        CheckAndNotify(response)

        if (CurrentTimestamp === getState().orders.OrdersLastTimestamp) {
            if (responseType) {
                Notify('Report has been successfully downloaded', 'Success', 'success')
                dispatch(setDownloadReportInProgress(false))
                window.open(`${getState().orders.BaseUrl}/${response.data.data.data.report_file_url}`, '_blank', 'noopener,noreferrer')
            } else {
                dispatch(setCurrentOrdersCountFiltered(response.data.data?.countData))
                dispatch(setOrdersList(response.data.data.data))
                if (getState().orders.OrdersSortConditions.isInverted === true) {
                    dispatch(setOrdersSortConditions(getState().orders.OrdersSortConditions.sortingColumn, null))
                }
            }
        }
    }).catch(error => {
        dispatch(setDownloadReportInProgress(false))
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    
    })
}

export const getCanceledList = (responseType, sortedColumns) => (dispatch, getState) => {
    if (responseType === 'file') Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    let CurrentTimestamp = new Date().getTime()
    dispatch(setOrdersLastTimestamp(CurrentTimestamp))
    dispatch(setOrdersCount(0))
    dispatch(getOrderTabsCountByConditions('cancel', CurrentTimestamp))
    let conditions = Object.assign({}, getState().orders.OrdersConditions)
    if (sortedColumns) {
        sortedColumns.forEach(c => {
            let sort_by
            if (c.sort === "asc") {
                sort_by = 'ASC'
            } else if (c.sort === "desc") {
                sort_by = 'DESC'
            }
            if (sort_by) {
                if (conditions[c.colId]) {
                    conditions[c.colId].order_by = sort_by
                } else {
                    conditions[c.colId] = {"order_by": sort_by}
                }
            }
        })
    }
    OrdersApi.postCanceledList(getState().auth.AuthToken,
        getState().orders.CurrentShops || (getState().orders.CurrentShop?.shop_id === "all" ? "all" : [getState().orders.CurrentShop?.shop_id]),
        getState().orders.PageSize,
        getState().orders.OrdersList.length,
        responseType === 'file' ? conditions : getState().orders.OrdersConditions, responseType).then(response => {
        CheckAndNotify(response)

        if (CurrentTimestamp === getState().orders.OrdersLastTimestamp) {
            if (responseType) {
                Notify('Report has been successfully downloaded', 'Success', 'success')
                dispatch(setDownloadReportInProgress(false))

                window.open(`${getState().orders.BaseUrl}/${response.data.data.data.report_file_url}`, '_blank', 'noopener,noreferrer')
            } else {
                dispatch(setCurrentOrdersCountFiltered(response.data.data?.countData))
                dispatch(setOrdersList(response.data.data.data))
                if (getState().orders.OrdersSortConditions.isInverted === true) {
                    dispatch(setOrdersSortConditions(getState().orders.OrdersSortConditions.sortingColumn, null))
                }
            }
        }
    }).catch(error => {
        dispatch(setDownloadReportInProgress(false))
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
       
    })
}

export const getNewList = (responseType, sortedColumns) => (dispatch, getState) => {
    if (responseType === 'file') Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    let CurrentTimestamp = new Date().getTime()
    dispatch(setOrdersLastTimestamp(CurrentTimestamp))
    dispatch(setOrdersCount(0))
    dispatch(getOrderTabsCountByConditions('new', CurrentTimestamp))
    let conditions = Object.assign({}, getState().orders.OrdersConditions)
    if (sortedColumns) {
        sortedColumns.forEach(c => {
            let sort_by
            if (c.sort === "asc") {
                sort_by = 'ASC'
            } else if (c.sort === "desc") {
                sort_by = 'DESC'
            }
            if (sort_by) {
                if (conditions[c.colId]) {
                    conditions[c.colId].order_by = sort_by
                } else {
                    conditions[c.colId] = {"order_by": sort_by}
                }
            }
        })
    }
    OrdersApi.postNewList(getState().auth.AuthToken,
        getState().orders.CurrentShops || (getState().orders.CurrentShop?.shop_id === "all" ? "all" : [getState().orders.CurrentShop?.shop_id]),
        getState().orders.PageSize,
        getState().orders.OrdersList.length,
        responseType === 'file' ? conditions : getState().orders.OrdersConditions, responseType).then(response => {
        CheckAndNotify(response
        )
        if (CurrentTimestamp === getState().orders.OrdersLastTimestamp) {
            if (responseType) {
                Notify('Report has been successfully downloaded', 'Success', 'success')
                dispatch(setDownloadReportInProgress(false))
                window.open(`${getState().orders.BaseUrl}/${response.data.data.data.report_file_url}`, '_blank', 'noopener,noreferrer')
            } else {
                dispatch(setCurrentOrdersCountFiltered(response.data.data?.countData))
                dispatch(setOrdersList(response.data.data.data))
                if (getState().orders.OrdersSortConditions.isInverted === true) {
                    dispatch(setOrdersSortConditions(getState().orders.OrdersSortConditions.sortingColumn, null))
                }
            }
        }
    }).catch(error => {
        dispatch(setDownloadReportInProgress(false))
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getInvalidOrdersList = (responseType) => (dispatch, getState) => {
    if (responseType === 'file') Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    let CurrentTimestamp = new Date().getTime()
    dispatch(setOrdersLastTimestamp(CurrentTimestamp))
    OrdersApi.postInvalidOrdersList(getState().auth.AuthToken,
        getState().orders.CurrentShops || [getState().orders.CurrentShop?.shop_id],
        getState().orders.PageSize,
        getState().orders.OrdersList.length,
        getState().orders.OrdersConditions, responseType).then(response => {
        CheckAndNotify(response)
        if (CurrentTimestamp === getState().orders.OrdersLastTimestamp) {
            if (responseType) {
                Notify('Report has been successfully downloaded', 'Success', 'success')
                dispatch(setDownloadReportInProgress(false))
                window.open(`${getState().orders.BaseUrl}/${response.data.data.data.report_file_url}`, '_blank', 'noopener,noreferrer')
            } else {
                dispatch(setCurrentOrdersCountFiltered(response.data.data?.countData))
                // TEST
                const tagsList = getState().tagReducer.TagsList

                const tagIdToName = (id) => {
                    return tagsList.find(el => Number(el.tag_id) === Number(id))?.tag_name
                }
                const responseData = response.data.data.data
                let orders_array = responseData

                if (tagsList.length > 0) {
                    orders_array = orders_array.map(el => ({
                        ...el,
                        order_tag: tagIdToName(el.order_tags[0])
                    }))
                }

                dispatch(setOrdersList(orders_array))
                // TEST
                if (getState().orders.OrdersSortConditions.isInverted === true) {
                    dispatch(setOrdersSortConditions(getState().orders.OrdersSortConditions.sortingColumn, null))
                }
            }
        }
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getOrderDetails = (order_id, selected) => (dispatch, getState) => {
    dispatch(setRatesErrorDisplayedOnce(false))
    OrdersApi.getOrderDetails(getState().auth.AuthToken, getState().orders?.CurrentShop?.shop_id, order_id).then(response => {
        CheckAndNotify(response)
        dispatch(setDetailsViewInfo(response.data.data, selected))
        const orderDitails = createProductQuantity(response.data.data)
        dispatch(setDetailsProductQuantity(orderDitails))
        dispatch(getLabelsList(order_id))
        if (getState().orders.BarCodePoNumber) {
            dispatch(setDetailsViewSelected(true))
            dispatch(setDetailsViewFullScreen(false))
            dispatch(openBarCodeByOrderId(null))
        }
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const postOrderDetailsEdit = (order_id, name, phone, country_id, address_line1, address_line2, address_line3, city, state_or_region, postal_code, total_shipping_price, personal_data_tax_id) => (dispatch, getState) => {
    OrdersApi.postOrderDetailsEdit(getState().auth.AuthToken, order_id, name, phone, country_id, address_line1, address_line2, address_line3, city, state_or_region, postal_code, total_shipping_price, personal_data_tax_id).then(response => {
        CheckAndNotify(response)
        dispatch(getOrderDetails(getState().orders.DetailsView.data.order.order_id, true))
        dispatch(setOrderDetailsEditActive(false))
        dispatch(setOrderDetailsEditSaveButtonActive(true))
        dispatch(removeDetailsEdit_all_validation_errors())
        Notify('Personal data has been updated', 'Succes', 'success')
    }).catch(error => {
        if (error.response.data?.validation_errors) { dispatch(setDetailsEdit_validation_errors(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
        dispatch(setOrderDetailsEditSaveButtonActive(true))
    })
}

export const getOrderDetailsDeclaration = (label_id, setIsFetching) => (dispatch, getState) => {
    setIsFetching(true)
    OrdersApi.postGetDeclaration(getState().auth.AuthToken, label_id).then(response => {
        CheckAndNotify(response)
        dispatch(setCustomDeclaration(response.data.data[0]))
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        setIsFetching(false)
    })
}

export const postOrderDetailsCreateDeclaration = (order_item_id, order_id, asin, title, packaged_quantity, country_id, item_value, hs_code, SKU, setIsFetching) => (dispatch, getState) => {
    setIsFetching(true)
    OrdersApi.postOrderUpdateDeclaration(getState().auth.AuthToken,
            order_item_id,
            order_id,
            asin,
            title, packaged_quantity, country_id, item_value, hs_code, SKU).then(response => {
                CheckAndNotify(response)
                dispatch(setCustomDeclarationInProgress(false))
                dispatch(setCostomDeclarationActive(false))
                    dispatch(setCreatedDeclarationList(asin))
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error) {
            if (error.response.data?.validation_errors) { dispatch(setCreateDeclarationValidationList(error.response.data.validation_errors)) }
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
        dispatch(setCustomDeclarationInProgress(false))
    }).finally(() => {
        setIsFetching(false)
    })
}

export const DeleteDeclaration = (order_item_id, order_id, asin, setIsFetching) => (dispatch, getState) => {
    setIsFetching(true)
    OrdersApi.deleteDeclaration(getState().auth.AuthToken,
        order_item_id,
        order_id,
        asin).then(response => {
            CheckAndNotify(response)
            dispatch(clearDeclarationList(asin))
        Notify(response.data.data[0], 'Succes', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
            if (error.response.data?.validation_errors) { dispatch(setCreateDeclarationValidationList(error.response.data.validation_errors)) }
        }
    }).finally(() => {
        setIsFetching(false)
    })
}

export const CheckCalculatorPrice = (order_id, package_weight_value, package_weight_unit_of_measure, package_dimension_length,
                                     package_dimension_width, package_dimension_height, package_dimension_unit_of_measure,
                                     delivery_service_type_id, delivery_package_id, bill_duties_taxes, hide_errors) => (dispatch, getState) => {
    OrdersApi.postCheckCalculatorPrice(getState().auth.AuthToken,
        order_id,
        package_weight_value, package_weight_unit_of_measure, package_dimension_length,
        package_dimension_width, package_dimension_height, package_dimension_unit_of_measure,
        delivery_service_type_id, delivery_package_id, bill_duties_taxes
        ).then(response => {
            CheckAndNotify(response)
            dispatch(clearCalculatorErrors())
        dispatch(setCalculatorPrices(response.data.data.data))
        dispatch(setCheckPriceInProgress(false))
        if (hide_errors !== "hide_errors") {
            if (!getState().orders.RatesErrorDisplayedOnce) {
                if (response.data.data.error_object.length > 0) {
                    [response.data.data.error_object].map(e => {
                        Notify(e.toString(), 'Error', 'error')
                        if (getState().upcReducer.ScalesDataFromLocalhost) {
                            dispatch(setRatesErrorDisplayedOnce(true))
                        }
                    })
                }
            }
        }
    }).catch(error => {
        if (hide_errors !== "hide_errors")  {
                if (error.response?.data?.validation_errors) { dispatch(setCalculatorErrorList(error.response.data.validation_errors)) }
                if (!getState().orders.RatesErrorDisplayedOnce) {
                    if (error) {
                        dispatch(LogOutOnAuthError(error))
                        if (error?.response?.data?.message) {
                            Notify(error?.response?.data?.message, `${error?.response?.data?.status || "Error"}`, 'error')
                            if (getState().upcReducer.ScalesDataFromLocalhost) {
                                dispatch(setRatesErrorDisplayedOnce(true))
                            }
                        }
                        if (error?.response?.data?.messages) {
                            error?.response?.data?.messages.map(m => {
                                Notify(m, `${error?.response?.data?.status || "Error"}`, 'error')
                                if (getState().upcReducer.ScalesDataFromLocalhost) {
                                    dispatch(setRatesErrorDisplayedOnce(true))
                                }
                            })
                        }
                    }
                }
        }
        dispatch(setCheckPriceInProgress(false))
    })
}

export const CalculatorCreateLabel =
  (
    order_items,
    package_weight_value,
    package_weight_unit_of_measure,
    package_dimension_length,
    package_dimension_width,
    package_dimension_height,
    package_dimension_unit_of_measure,
    delivery_service_type_id,
    delivery_package_id,
    rate,
    delivery_date,
    bill_duties_taxes,
    enable_tax_id,
    confirmation,
    service_custom_fields,
    is_scales,
    dimension_method_id,
    is_final_shipping_label
  ) =>
  (dispatch, getState) => {
    dispatch(setCreateLabelInProgress(true))
    OrdersApi.postCreateLabel(
      getState().auth.AuthToken,
      getState().orders.CurrentShop?.shop_id,
      order_items,
      package_weight_value,
      package_weight_unit_of_measure,
      package_dimension_length,
      package_dimension_width,
      package_dimension_height,
      package_dimension_unit_of_measure,
      delivery_service_type_id,
      delivery_package_id,
      rate,
      delivery_date,
      bill_duties_taxes,
      enable_tax_id,
      confirmation,
      service_custom_fields,
      is_scales,
      dimension_method_id,
      is_final_shipping_label
    )
      .then((response) => {
        CheckAndNotify(response)
        dispatch(setCreateLabelInProgress(false))
        if (Array.isArray(response.data.data)) {
          dispatch(setLabelsList(response.data.data))
        } else {
          dispatch(setLabelsList([response.data.data]))
        }
        dispatch(getLabelsList(order_id))
        if (response.data.data.error) {
          Notify(response.data.data.error.toString(), "Error", "error")
        } else {
          Notify("Label(s) has been Created", "Succes", "success")
        }
      })
      .catch((error) => {
        dispatch(setCreateLabelInProgress(false))
        if (error) {
          if (error?.response?.data?.validation_errors) {
            dispatch(
              setCalculatorErrorList(error?.response?.data?.validation_errors)
            )
          }
          dispatch(LogOutOnAuthError(error))
          CheckAndNotifyErrors(error)
          if (error?.response?.data?.error_object?.invalidParams) {
            error?.response?.data.error_object.invalidParams.map((el) => el) //Notify(el.reason, el.name, 'error'))
          }
        }
      })
  }

export const getCalculatorServices = () => (dispatch, getState) => {
    commonApi.getCalculatorServices(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        let Services = []
        /*for (let service in response.data.data) {
            response.data.data.map(v => { Services.push({'delivery_service_type_name': v.name + ' ' + v['delivery_service_type_name'], 'delivery_service_type_id': v['delivery_service_type_id']}) })
        }*/
        for (let service in response.data.data) {
            response.data.data[service].map(v => { Services.push({'delivery_service_type_name': String(service) + ' ' + v['delivery_service_type_name'], 'delivery_service_type_id': v['delivery_service_type_id'], 'delivery_service_id': v['delivery_service_id']}) })
        }
        dispatch(setServices(Services))

    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getCalculatorConfirmations = () => (dispatch, getState) => {
    commonApi.getCalculatorConfirmations(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        let ResponseDataServices = response.data.data
        dispatch(SetConfirmations(ResponseDataServices))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getCalculatorDeliveryPackages = (delivery_service_type_id) => (dispatch, getState) => {
    commonApi.getCalculatorDeliveryPackages(getState().auth.AuthToken, delivery_service_type_id).then(response => {
        CheckAndNotify(response)
        dispatch(setDeliveryPackages(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
/*
            CheckAndNotifyErrors(error)
*/
        }
    })
}

export const getCountries = () => (dispatch, getState) => {
    commonApi.getCountries(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setCountries(response.data.data))
    }).catch(error => {
        if (error) {
            CheckAndNotifyErrors(error)
        }
    })
}

export const scanBarCode = (key_name, key_value) => (dispatch, getState) => {
    dispatch(setScanBarCode_ResponseReceived(false))
    OrdersApi.scanBarCode(getState().auth.AuthToken, key_name, key_value).then(response => {
        CheckAndNotify(response)
        dispatch(setBarCodesList(response.data.data))
    }).catch(error => {
        dispatch(setBarCodesList([]))
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        dispatch(setScanBarCode_ResponseReceived(true))
    })
}

export const importOrders = (file, shop_id, setIsFetching) => (dispatch, getState) => {
    setIsFetching(true)
    OrdersApi.importOrders(getState().auth.AuthToken, file, shop_id).then(response => {
        CheckAndNotify(response)
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        setIsFetching(false)
    })
}

export const addManualOrder = (order_id, name, phone, country_id,
                               address_line1, address_line2, address_line3,
                               city, state_or_region, postal_code, ship_by, order_items, setIsFetching) => (dispatch, getState) => {
    setIsFetching(true)
    OrdersApi.addManualOrder(getState().auth.AuthToken, order_id, name, phone, country_id,
        address_line1, address_line2, address_line3,
        city, state_or_region, postal_code, ship_by, order_items).then(response => {
        CheckAndNotify(response)
        Notify(response.data.message, 'Succes', 'success')
        dispatch(setMaualOrderStatus(response.data.status, false))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            dispatch(setMaualOrderStatus(error.response.data.status, false))
            if (error?.response?.data?.validation_errors) { dispatch(setManualOrderValidationErrors(error.response.data.validation_errors)) }
            CheckAndNotifyErrors(error)
        }

    }).finally(() => {
        setIsFetching(false)
    })
}

export const getHsCodes = () => (dispatch, getState) => {
    OrdersApi.getHsCodes(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setHsCodes(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getLabelsList = (order_id, cb) => (dispatch, getState) => {
    dispatch(setLabelsDataList([]))
    cb && cb(true)
    OrdersApi.getLabelsList(getState().auth.AuthToken, order_id).then(response => {
        CheckAndNotify(response)
        dispatch(setLabelsDataList(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        cb && cb(false)
    })
}

export const getLabelsListForTaxIdRules = (order_id) => (dispatch, getState) => {
    OrdersApi.getLabelsList(getState().auth.AuthToken, order_id).then(response => {
        CheckAndNotify(response)
        dispatch(setLabelsListForTaxIdRules(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const VoidLabel = (label_id, setIsFetching, order_id) => (dispatch, getState) => {
    setIsFetching(true)
    OrdersApi.voidLabel(getState().auth.AuthToken, label_id).then(response => {
        dispatch(getLabelsList(order_id))
        CheckAndNotify(response, true)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        setIsFetching(false)
    })
}

export const getShopsList = (token) => (dispatch, getState) => {
    OrdersApi.getShopsList(token).then(response => {
        CheckAndNotify(response)
        dispatch(setShopsList(response.data.data))
        /*if (!JSON.parse(window.localStorage.getItem('OrdersShop'))) {
            dispatch(setCurrentShop(response.data.data[0]))
            window.localStorage.setItem('OrdersShop', JSON.stringify(response.data.data[0]))
        }*/
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const exportLabels = (date, setIsFetching) => (dispatch, getState) => {
    setIsFetching(true)
    OrdersApi.exportLabels(getState().auth.AuthToken, date).then(response => {
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        setIsFetching(false)
    })
}

export const editOrderItemStatus = (item_id, status_id, setIsFetching, page_id) => (dispatch, getState) => {
    setIsFetching(true)
    OrdersApi.editOrderItemStatus(getState().auth.AuthToken, item_id, status_id, page_id).then(response => {
        CheckAndNotify(response)
        dispatch(getOrderTabsCount(getState().orders.CurrentShop.shop_id))
        dispatch(getShopsList())
        if (response.data.message) {
            Notify(response.data.message, 'Success', 'success')

        }
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        setIsFetching(false)
    })
}


export const getOrderTabsCount = (shop_id) => (dispatch, getState) => {
    if (shop_id === "all") {
        OrdersApi.postOrderTabsCountAllOrders(getState().auth.AuthToken, getState().orders.CurrentShops || [getState().orders.CurrentShop?.shop_id]).then(response => {
            CheckAndNotify(response)
            dispatch(setCurrentShopOrdersCount(response.data.data))
        }).catch(error => {
            dispatch(LogOutOnAuthError(error))
            dispatch(setCurrentShopOrdersCount(null))
        })
    } else {
        OrdersApi.postOrderTabsCount(getState().auth.AuthToken, getState().orders.CurrentShops || [getState().orders.CurrentShop?.shop_id]).then(response => {
            CheckAndNotify(response)
            dispatch(setCurrentShopOrdersCount(response.data.data))

        }).catch(error => {
            if (error) {
                dispatch(LogOutOnAuthError(error))
                CheckAndNotifyErrors(error)
            }
        })
    }
}
export const getOrderTabsCountByConditions = (tab_name, currentTimestamp) => (dispatch, getState) => {
        let conditions = getState().orders.OrdersConditions
        let shop_id = getState().orders.CurrentShops || (getState().orders.CurrentShop?.shop_id === "all" ? "all" : [getState().orders.CurrentShop?.shop_id])
        let obj = {}
        if (shop_id !== "all") {
            obj = {
                "conditions": conditions,
                "shop_id": shop_id
            }
        } else {
            obj = {
                "conditions": conditions
            }
        }
        OrdersApi.getOrderTabsCountByConditions(getState().auth.AuthToken, tab_name, obj).then(response => {
            CheckAndNotify(response)
            if (currentTimestamp === getState().orders.OrdersLastTimestamp) {
                if (response.data.data.countData) {
                    dispatch(setOrdersCount(response.data.data.countData))
                } else {
                    dispatch(setOrdersCount(0))
                }
            }
        }).catch(error => {
            dispatch(LogOutOnAuthError(error))
            dispatch(setCurrentShopOrdersCount(null))
        })
}

export const getChatList = (order_id) => (dispatch, getState) => {
    OrdersApi.postOrderChatList(getState().auth.AuthToken, order_id).then(response => {
        CheckAndNotify(response)
        dispatch(setChatDataList(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
/*
            CheckAndNotifyErrors(error)
*/
        }
    })
}

export const getCustomList = () => (dispatch, getState) => {
    OrdersApi.getCustomFields(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setCustomField(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const CreateOrderMessage = (order_id, message, isFetching) => (dispatch, getState) => {
    isFetching(true)
    OrdersApi.postCreateOrderMessage(getState().auth.AuthToken, order_id, message).then(response => {
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        isFetching(false)
    })
}

export const CreateInvoice = (order_id, tracker_number, enable_tax_id) => (dispatch, getState) => {
    OrdersApi.postCreateInvoice(getState().auth.AuthToken, order_id, tracker_number, enable_tax_id).then(response => {
        CheckAndNotify(response)
        window.open(`${getState().orders.BaseUrl}${response.data.data[0]}`, '_blank', 'noopener,noreferrer')
/*
        dispatch(setCommercialInvoices(response.data.data))
*/
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const AutoCreateOrderDeclaration = (order_item_id, order_id) => (dispatch, getState) => {
    OrdersApi.AutoCreateOrderDeclaration(getState().auth.AuthToken, order_item_id).then(response => {
        CheckAndNotify(response)
        if (response.data.message && response.data.status === "OK") {
            Notify(response.data.message, 'Success', 'success')
        }
        let details_order_id = getState().orders.DetailsView?.data.order.order_id
        if (details_order_id) {
            dispatch(getOrderDetails(details_order_id, true))
        }
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
/*
            CheckAndNotifyErrors(error)
*/
        }
    })
}

export const AutoCreateOrderDeclarationManual = (order_item_id, order_id) => (dispatch, getState) => {
    OrdersApi.AutoCreateOrderDeclarationManual(getState().auth.AuthToken, order_item_id).then(response => {
        CheckAndNotify(response)
        if (response.data.message) {
            Notify(response.data.message, 'Success', 'success')
        }
        dispatch(getOrderDetails(order_id, true))
    }).catch(error => {
        dispatch(setAutoCreateDeclarationManualStatus(order_item_id))
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const AutoCreateOrderLabel = (order_items, package_dimension_length, package_dimension_width, package_dimension_height, package_weight_value, is_scales, isFetching, is_scan_page) => (dispatch, getState) => {

    isFetching(true)
    localStorage.setItem("isFetchingLabel", true)

    OrdersApi.AutoCreateLabel(getState().auth.AuthToken, order_items, package_dimension_length, package_dimension_width, package_dimension_height, package_weight_value, is_scales, is_scan_page).then(response => {
        CheckAndNotify(response)
        if (Array.isArray(response.data.data)) {
            dispatch(setLabelsList(response.data.data))
        } else {
            dispatch(setLabelsList([response.data.data])) 
        }
        if (response.data.message) {
            Notify(response.data.message, 'Success', 'success')
        }
        localStorage.setItem("isPrnitLabelResponseError", false)
    }).catch(error => {
            if (error) {
            localStorage.setItem("isPrnitLabelResponseError", true)
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        isFetching(false)
        localStorage.setItem("isFetchingLabel", false)
    })
}

export const SetLabelList = (labelList) => (dispatch, getState) => {
    if (Array.isArray(labelList)) {
        dispatch(setLabelsList(labelList))
    } else {
        dispatch(setLabelsList([labelList])) 
    }
}

export const SetReturnItemStatus = (order_item_id) => (dispatch, getState) => {
    OrdersApi.setReturnItemStatus(getState().auth.AuthToken, order_item_id).then(response => {
        if (response.data.message) {
            Notify(response.data.message, 'Success', 'success')
        }
    }).catch(error => {
        if (error) {
            CheckAndNotifyErrors(error)
        }
    })
}

export const DownloadCancelledReportByRange = (date_from, date_to, name) => (dispatch, getState) => {
    Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    dispatch(setRangeReportInProgress(true))
    let apiFunction
    if (name === 'cancelled-all') {
        apiFunction = OrdersApi.ReportCancelledAllByRange(getState().auth.AuthToken, date_from, date_to)
    } else {
        apiFunction = OrdersApi.ReportCancelledByRange(getState().auth.AuthToken, date_from, date_to)
    }
    apiFunction.then(response => {
        CheckAndNotify(response)
        window.open(`${getState().orders.BaseUrl}${response.data.data.report_url.slice(4)}`, '_blank', 'noopener,noreferrer')
        if (response.data.message) {
            Notify(response.data.message, 'Success', 'success')
        }
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        dispatch(setRangeReportInProgress(false))
    })
}

export const DownloadReportByRange = (date_from, date_to, type_of_report) => (dispatch, getState) => {
    Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    dispatch(setRangeReportInProgress(true))
    OrdersApi.ReportByRange(getState().auth.AuthToken, date_from, date_to, type_of_report).then(response => {
        CheckAndNotify(response)
        window.open(`${getState().orders.BaseUrl}${response.data.data.report_url.slice(4)}`, '_blank', 'noopener,noreferrer')
        if (response.data.message) {
            Notify(response.data.message, 'Success', 'success')
        }
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        dispatch(setRangeReportInProgress(false))
    })
}


export const DownloadReturnRequestReportByRange = (date_from, date_to, type) => (dispatch, getState) => {
    Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    OrdersApi.ReturnRequestReportByRange(getState().auth.AuthToken, date_from, date_to, type).then(response => {
        CheckAndNotify(response)
        window.open(`${getState().orders.BaseUrl}${response.data.data.report_url.slice(4)}`, '_blank', 'noopener,noreferrer')
        if (response.data.message) {
            Notify(response.data.message, 'Success', 'success')
        }
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}


export const DownloadReturnUploadLabelsReport = (date_from, date_to, type) => (dispatch, getState) => {
    Notify('Report in progress. Wait a few seconds', 'Info', 'info')
    OrdersApi.getDownloadReturnUploadLabelsReport(getState().auth.AuthToken, date_from, date_to, type).then(response => {
        CheckAndNotify(response)
        window.open(`${getState().orders.BaseUrl}${response.data.data.report_url.slice(4)}`, '_blank', 'noopener,noreferrer')
        if (response.data.message) {
            Notify(response.data.message, 'Success', 'success')
        }
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getCompanyShopsList  = () => (dispatch, getState) => {
    OrdersApi.getCompanyShopsList(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setCompanyShopsList(response.data.data))

        /*if (!JSON.parse(window.localStorage.getItem('OrdersShop'))) {
            dispatch(setCurrentShop(response.data.data[0]))
            window.localStorage.setItem('OrdersShop', JSON.stringify(response.data.data[0]))
        }*/
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const uploadPersonalData = (file) => (dispatch, getState) => {
    OrdersApi.uploadPersonalData(getState().auth.AuthToken, file).then(response => {
        dispatch(getUploadingFilesList())
        setTimeout(() => dispatch(getUploadingFilesList()), 5000)
        CheckAndNotify(response)
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const setScalesData = (scales_data) => ({type: SET_SCALES_DATA, scales_data})
export const getScalesData = (orderItemId) => (dispatch, getState) => {
    OrdersApi.getDataFromScales(getState().auth.AuthToken, orderItemId).then(response => {
        CheckAndNotify(response)
        dispatch(setScalesData(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const PrintBulkOrders = (orders_ids_array) => (dispatch, getState) => {
    OrdersApi.printBulkOrders(getState().auth.AuthToken, orders_ids_array).then(response => {
        CheckAndNotify(response, true)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const PrintBulkOrdersByParams = (company_ids, marketplace_ids, shop_ids, status, ship_by_from, ship_by_to, order_date_from, order_date_to) => (dispatch, getState) => {
    OrdersApi.PrintBulkOrdersByParams(getState().auth.AuthToken, company_ids, marketplace_ids, shop_ids, status, ship_by_from, ship_by_to, order_date_from, order_date_to).then(response => {
        CheckAndNotify(response, true)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getOrderItemsProblems = (order_id) => (dispatch, getState) => {
    dispatch(setOrderItemsProblems([]))
    OrdersApi.getOrderItemsProblems(getState().auth.AuthToken, order_id).then(response => {
        dispatch(setOrderItemsProblems(response.data.data))
        CheckAndNotify(response, false)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const setOrderItemTag = (orders, tag_id, order_id) => (dispatch, getState) => {
    OrdersApi.setOrderItemTag(getState().auth.AuthToken, orders, tag_id).then(response => {
        CheckAndNotify(response, false)
        dispatch(getOrderDetails(order_id, true))
    })
}

export const deleteOrderItemTag = (orders, tag_id, order_id) => (dispatch, getState) => {
    OrdersApi.deleteOrderItemTag(getState().auth.AuthToken, orders, tag_id).then(response => {
        CheckAndNotify(response, false)
        dispatch(getOrderDetails(order_id, true))
    })
}

export const getReturnLabelsList = (order_id) => (dispatch, getState) => {
    dispatch(setReturnLabelsList([]))
    OrdersApi.getReturnLabelsList(getState().auth.AuthToken, order_id).then(response => {
        const responseArray = response.data.data
        if (responseArray?.length > 0) {
            dispatch(setReturnLabelsList(responseArray))
        }
        CheckAndNotify(response, false)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getOrdersValidationErrors = (order_id) => (dispatch, getState) => {
    dispatch(setOrdersValidationErrors(null))
    OrdersApi.getOrdersValidationErrors(getState().auth.AuthToken, order_id).then(response => {
        const responseArray = response.data.data
        if (responseArray) {
            dispatch(setOrdersValidationErrors(responseArray))
        }

        CheckAndNotify(response, false)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}


export const getOrderItemTagList = () => (dispatch, getState) => {
    OrdersApi.getOrderItemTagList(getState().auth.AuthToken).then(response => {
        const responseArray = response.data.data
        if (responseArray) {
            dispatch(setOrderItemTagList(responseArray))
        }

        CheckAndNotify(response, false)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default ordersReducer
